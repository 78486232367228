import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../components/Layout';
import { AuthorData, TextTaleData } from '../types';


interface TalePageTemplateData {
    textTales: TextTaleData;
    authors?: AuthorData;
}

const useStyles = makeStyles((theme) => ({
    titleWrap: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    title: {
        margin: '0 10px 0 0',
    },
    timeToRead: {
        fontSize: 14,
    },
    author: {
        width: '100%',
        fontSize: 20,
        textAlign: 'center',
    }
}));

export const TextTalePageTemplate: FC<PageProps<TalePageTemplateData>> = ({data, location}) => {
    const { textTales: {content, name, timeToRead}, authors } = data;
    const classes = useStyles();

    return (
        <Layout location={location} seoTitle={name}>
            {authors && <strong className={classes.author}>{authors.name}</strong>}
            <div className={classes.titleWrap}>
                <h1 className={classes.title}>{name}</h1>
                {timeToRead && <span className={classes.timeToRead}>{timeToRead}</span>}
            </div>
            {' '}
            <div className="tale-page content" dangerouslySetInnerHTML={{__html: content }} />
        </Layout>
    );
}


export const query = graphql`
  query TextTalePageTemplateQuery($slug: String!, $author_id: Int) {
      textTales(slug: {eq: $slug}) {
        _id
        slug
        name
        content
        categories {
            id
        }
        timeToRead
        author_id
        visits
      }
      authors(_id: {eq: $author_id}) {
            name
            slug
            _id
        }
  }
`
export default TextTalePageTemplate;
